<template>
  <div>404 Not found</div>
</template>

<script>
export default {
  metaInfo: {
    title: '404'
  }
}
</script>
